import React from 'react';
import { CSSReset, ThemeProvider } from '@chakra-ui/core';
import { Global, css } from '@emotion/core';

import theme from '@/utils/theme';

const Provider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Global
        styles={css`
          html {
            color: #333333;
            font-family: 'Montserrat', sans-serif;
            height: 100%;
          }
          html,
          body,
          #root {
            height: 100%;
          }
          body {
            overflow-y: scroll;
          }
        `}
      />
      {children}
    </ThemeProvider>
  );
};

export default Provider;
